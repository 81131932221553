/**
 * @generated SignedSource<<bdc255471b2c252685d1ccd6e7dc2c08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectMapPart_ProjectInScenarioFragment$data = {
  readonly project: {
    readonly name: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ProjectMap_ProjectFragment" | "assignmentsInProject_ProjectFragment">;
  readonly " $fragmentType": "ProjectMapPart_ProjectInScenarioFragment";
};
export type ProjectMapPart_ProjectInScenarioFragment$key = {
  readonly " $data"?: ProjectMapPart_ProjectInScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectMapPart_ProjectInScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectMapPart_ProjectInScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "assignmentsInProject_ProjectFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectMap_ProjectFragment"
    }
  ],
  "type": "ProjectInScenario",
  "abstractKey": null
};

(node as any).hash = "aa899448cbcc26987ec972a0c71c49c4";

export default node;
