/**
 * @generated SignedSource<<c4b9497a502261fb61c15fe9034eed12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type assignmentsInProject_ProjectFragment$data = {
  readonly assignments: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"assignmentsInProject_AssignmentInlineFragment">;
      };
    } | null> | null;
  };
  readonly id: string;
  readonly project: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CreateAssignmentButton_ProjectFragment" | "CreateAssignmentsFromTemplateButton_ProjectFragment" | "assignmentsInProjectList_ProjectFragment">;
  };
  readonly " $fragmentType": "assignmentsInProject_ProjectFragment";
};
export type assignmentsInProject_ProjectFragment$key = {
  readonly " $data"?: assignmentsInProject_ProjectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"assignmentsInProject_ProjectFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sortOrder",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "assignmentsInProject_ProjectFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "assignmentsInProjectList_ProjectFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateAssignmentButton_ProjectFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateAssignmentsFromTemplateButton_ProjectFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentTypesConnection",
      "kind": "LinkedField",
      "name": "assignments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssignmentTypesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Assignment",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "assignmentsInProject_AssignmentInlineFragment",
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssignmentRole",
                      "kind": "LinkedField",
                      "name": "validAssignmentRoles",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "AssignmentCard_AssignmentFragment"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Tag",
                      "kind": "LinkedField",
                      "name": "tags",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TagData",
                          "kind": "LinkedField",
                          "name": "data",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "color",
                              "storageKey": null
                            },
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "name",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectInScenario",
  "abstractKey": null
};
})();

(node as any).hash = "ee49d1761b4a7cb5f1ce166d2afb853f";

export default node;
