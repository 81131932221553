/**
 * @generated SignedSource<<4e58f10994f46680f194667872e71c53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AssignmentStatus = "Assigned" | "Either" | "Empty";
export type Staffing = "EitherStaffed" | "FullyStaffed" | "NotFullyStaffed";
export type UtilizationStatus = "FullyAllocated" | "NotAllocated" | "Overallocated" | "Underallocated";
export type ExportAssignmentsInput = {
  clientMutationId?: string | null;
  input: GetExportAssignmentsStrategyInput;
};
export type GetExportAssignmentsStrategyInput = {
  kind?: string | null;
  personFiltersOpt: PersonOnAssignmentFiltersInput;
  projectFiltersOpt: ProjectWithAssignmentsFiltersInput;
  scenarioRef: string;
};
export type ProjectWithAssignmentsFiltersInput = {
  assignmentStatus?: AssignmentStatus | null;
  budgetedLaborCosts?: BigDecimalRangeFilterInput | null;
  divisions?: ReadonlyArray<string> | null;
  executives?: ReadonlyArray<string> | null;
  generalConditionsPercentage?: BigDecimalRangeFilterInput | null;
  ids?: ReadonlyArray<string> | null;
  inDateRange?: LocalDateRangeFilterInput | null;
  name?: string | null;
  regions?: ReadonlyArray<string> | null;
  skillIds?: ReadonlyArray<string> | null;
  staffing?: Staffing | null;
  stages?: ReadonlyArray<string> | null;
  volume?: BigDecimalRangeFilterInput | null;
};
export type BigDecimalRangeFilterInput = {
  from?: number | null;
  to?: number | null;
};
export type LocalDateRangeFilterInput = {
  from?: any | null;
  to?: any | null;
};
export type PersonOnAssignmentFiltersInput = {
  assignmentInDateRange?: LocalDateRangeFilterInput | null;
  assignmentStatus?: AssignmentStatus | null;
  assignmentTags?: ReadonlyArray<string> | null;
  currentlyAssignedAssignmentRoles?: ReadonlyArray<string> | null;
  divisions?: ReadonlyArray<string> | null;
  email?: string | null;
  executives?: ReadonlyArray<string> | null;
  gapDays?: IntRangeFilterInput | null;
  ids?: ReadonlyArray<string> | null;
  jobTitles?: ReadonlyArray<string> | null;
  laborBurdenMultiplier?: BigDecimalRangeFilterInput | null;
  name?: string | null;
  regions?: ReadonlyArray<string> | null;
  salary?: BigDecimalRangeFilterInput | null;
  skillFilters?: ReadonlyArray<SkillFilter> | null;
  startDate?: LocalDateRangeFilterInput | null;
  utilizationStatuses?: ReadonlyArray<UtilizationStatus> | null;
  utilizationWindow?: UtilizationWindowInput | null;
};
export type UtilizationWindowInput = {
  utilizationEnd: any;
  utilizationStart: any;
};
export type IntRangeFilterInput = {
  from?: number | null;
  to?: number | null;
};
export type SkillFilter = {
  hasSkillOpt?: boolean | null;
  maxOpt?: number | null;
  minOpt?: number | null;
  skillId: string;
};
export type exportAssignmentsButton_ExportAssignmentsMutation$variables = {
  input: ExportAssignmentsInput;
};
export type exportAssignmentsButton_ExportAssignmentsMutation$data = {
  readonly Export: {
    readonly exportAssignments: {
      readonly file: {
        readonly url: string | null;
      };
    } | null;
  };
};
export type exportAssignmentsButton_ExportAssignmentsMutation = {
  response: exportAssignmentsButton_ExportAssignmentsMutation$data;
  variables: exportAssignmentsButton_ExportAssignmentsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "exportAssignmentsButton_ExportAssignmentsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExportMutations",
        "kind": "LinkedField",
        "name": "Export",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ExportAssignmentsPayload",
            "kind": "LinkedField",
            "name": "exportAssignments",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "exportAssignmentsButton_ExportAssignmentsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExportMutations",
        "kind": "LinkedField",
        "name": "Export",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "ExportAssignmentsPayload",
            "kind": "LinkedField",
            "name": "exportAssignments",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "24fac52a23a38a494291b3e339e0d91f",
    "id": null,
    "metadata": {},
    "name": "exportAssignmentsButton_ExportAssignmentsMutation",
    "operationKind": "mutation",
    "text": "mutation exportAssignmentsButton_ExportAssignmentsMutation(\n  $input: ExportAssignmentsInput!\n) {\n  Export {\n    exportAssignments(input: $input) {\n      file {\n        url\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "af799599dd77e85d1ddd4d10d49c8365";

export default node;
