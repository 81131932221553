/**
 * @generated SignedSource<<d23899e868df6a661fd62fece0dabb94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type assignmentsInProjectList_ProjectFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "assignmentsInProjectList_ProjectFragment";
};
export type assignmentsInProjectList_ProjectFragment$key = {
  readonly " $data"?: assignmentsInProjectList_ProjectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"assignmentsInProjectList_ProjectFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "assignmentsInProjectList_ProjectFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "0f1bbf0db454adcb902ffc8ee6b47d2f";

export default node;
