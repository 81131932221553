/**
 * @generated SignedSource<<5365880e0bb71698a8abe73842bde002>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type defaultSetTagsInputField_Query$variables = {
  name?: string | null;
};
export type defaultSetTagsInputField_Query$data = {
  readonly Tag: {
    readonly GetTagsByName: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"defaultSetTagsInputField_TagInlineFragment">;
        };
      } | null> | null;
    };
  };
};
export type defaultSetTagsInputField_Query = {
  response: defaultSetTagsInputField_Query$data;
  variables: defaultSetTagsInputField_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TagData",
    "kind": "LinkedField",
    "name": "data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "defaultSetTagsInputField_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TagQueries",
        "kind": "LinkedField",
        "name": "Tag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "TagsConnection",
            "kind": "LinkedField",
            "name": "GetTagsByName",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TagsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tag",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "defaultSetTagsInputField_TagInlineFragment",
                        "selections": (v2/*: any*/),
                        "args": null,
                        "argumentDefinitions": []
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "defaultSetTagsInputField_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TagQueries",
        "kind": "LinkedField",
        "name": "Tag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "TagsConnection",
            "kind": "LinkedField",
            "name": "GetTagsByName",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TagsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tag",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "04a160ebfeb5224a5a0d78fbfc87dba0",
    "id": null,
    "metadata": {},
    "name": "defaultSetTagsInputField_Query",
    "operationKind": "query",
    "text": "query defaultSetTagsInputField_Query(\n  $name: String\n) {\n  Tag {\n    GetTagsByName(name: $name, first: 50) {\n      edges {\n        node {\n          ...defaultSetTagsInputField_TagInlineFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment defaultSetTagsInputField_TagInlineFragment on Tag {\n  id\n  data {\n    name\n    color\n  }\n}\n"
  }
};
})();

(node as any).hash = "361ee1b304e179922292e2bbf28f30cd";

export default node;
