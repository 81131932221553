/**
 * @generated SignedSource<<2405caab76cd6c2e12d52665a1da4f9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type Permission = "AccountPermission_Auth_DriveTimesEnabled" | "AccountPermission_Auth_Field" | "AccountPermission_Auth_GapDaysEnabled" | "AccountPermission_Auth_PreConIntegration" | "AccountPermission_Auth_Reports" | "AccountPermission_Rand_DataWarehouseIntegration" | "AccountPermission_System_Root" | "UserInAccountPermission_Assessment_Edit" | "UserInAccountPermission_AssignmentRole_Edit" | "UserInAccountPermission_Division_Edit" | "UserInAccountPermission_Files_Delete" | "UserInAccountPermission_Management_Management" | "UserInAccountPermission_Maps_Read" | "UserInAccountPermission_Precon_Edit" | "UserInAccountPermission_PrivateData_Read" | "UserInAccountPermission_ProjectStages_Edit" | "UserInAccountPermission_Project_Edit" | "UserInAccountPermission_Region_Edit" | "UserInAccountPermission_Salary_Read" | "UserInAccountPermission_Scenario_Budgets" | "UserInAccountPermission_Scenario_Edit" | "UserInAccountPermission_Scenario_Masterplan" | "UserInAccountPermission_Skills_Edit" | "UserInAccountPermission_Skills_Read" | "UserInAccountPermission_Staff_Edit" | "UserInAccountPermission_System_Owner" | "UserInAccountPermission_Tag_AdminEdit" | "UserInAccountPermission_Tag_UserEdit" | "UserInAccountPermission_Tasks_Tasks" | "UserInAccountPermission_Templates_Edit" | "UserInAccountPermission_Utilization_Read";
import { FragmentRefs } from "relay-runtime";
export type userInAccountGroupsTable_userInAccountGroupInlineFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly permissions: ReadonlyArray<Permission>;
  readonly " $fragmentSpreads": FragmentRefs<"editUserInAccountGroupButton_GroupFragment">;
  readonly " $fragmentType": "userInAccountGroupsTable_userInAccountGroupInlineFragment";
};
export type userInAccountGroupsTable_userInAccountGroupInlineFragment$key = {
  readonly " $data"?: userInAccountGroupsTable_userInAccountGroupInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"userInAccountGroupsTable_userInAccountGroupInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "userInAccountGroupsTable_userInAccountGroupInlineFragment"
};

(node as any).hash = "fbf590e5769485d42aa17cb65e62270d";

export default node;
