/**
 * @generated SignedSource<<459ff759166bf86436eff3be2a803d67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type defaultSetTagsInputField_TagInlineFragment$data = {
  readonly data: {
    readonly color: string;
    readonly name: string;
  };
  readonly id: string;
  readonly " $fragmentType": "defaultSetTagsInputField_TagInlineFragment";
};
export type defaultSetTagsInputField_TagInlineFragment$key = {
  readonly " $data"?: defaultSetTagsInputField_TagInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"defaultSetTagsInputField_TagInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "defaultSetTagsInputField_TagInlineFragment"
};

(node as any).hash = "191771a45338d6718f06c50011baf0a1";

export default node;
