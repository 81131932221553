/**
 * @generated SignedSource<<c29bfd46bd238351583b7088c40ca47f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetTagSortOrderInput = {
  clientMutationId?: string | null;
  sourceId: string;
  targetId: string;
};
export type tagsTable_SetTagsSortOrderMutation$variables = {
  input: SetTagSortOrderInput;
};
export type tagsTable_SetTagsSortOrderMutation$data = {
  readonly Tag: {
    readonly setTagSortOrder: {
      readonly changedTags: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"tagsTable_TagInlineFragment">;
      }>;
    } | null;
  };
};
export type tagsTable_SetTagsSortOrderMutation = {
  response: tagsTable_SetTagsSortOrderMutation$data;
  variables: tagsTable_SetTagsSortOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "TagData",
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sortOrder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "tagsTable_SetTagsSortOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TagMutations",
        "kind": "LinkedField",
        "name": "Tag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SetTagSortOrderPayload",
            "kind": "LinkedField",
            "name": "setTagSortOrder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "changedTags",
                "plural": true,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "tagsTable_TagInlineFragment",
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editTagButton_TagFragment"
                      }
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "tagsTable_SetTagsSortOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TagMutations",
        "kind": "LinkedField",
        "name": "Tag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SetTagSortOrderPayload",
            "kind": "LinkedField",
            "name": "setTagSortOrder",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "changedTags",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d77b1dc3d096641e1a2581d023246c6b",
    "id": null,
    "metadata": {},
    "name": "tagsTable_SetTagsSortOrderMutation",
    "operationKind": "mutation",
    "text": "mutation tagsTable_SetTagsSortOrderMutation(\n  $input: SetTagSortOrderInput!\n) {\n  Tag {\n    setTagSortOrder(input: $input) {\n      changedTags {\n        ...tagsTable_TagInlineFragment\n        id\n      }\n    }\n  }\n}\n\nfragment editTagButton_TagFragment on Tag {\n  id\n  data {\n    name\n    color\n    sortOrder\n  }\n}\n\nfragment tagsTable_TagInlineFragment on Tag {\n  id\n  data {\n    name\n    sortOrder\n    color\n  }\n  ...editTagButton_TagFragment\n}\n"
  }
};
})();

(node as any).hash = "0b4c033a1fad05a6d8b2d16f369da1d3";

export default node;
