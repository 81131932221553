/**
 * @generated SignedSource<<f12bd4418fed9e156962d26d4f7a5762>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SoftDeleteTagsInput = {
  clientMutationId?: string | null;
  ids: ReadonlyArray<string>;
};
export type deleteTagButton_deleteTagMutation$variables = {
  connections: ReadonlyArray<string>;
  input: SoftDeleteTagsInput;
};
export type deleteTagButton_deleteTagMutation$data = {
  readonly Tag: {
    readonly softDeleteTags: {
      readonly cleanedTags: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"tagsTable_TagInlineFragment">;
        };
      }>;
      readonly deletedIds: ReadonlyArray<string>;
    } | null;
  };
};
export type deleteTagButton_deleteTagMutation = {
  response: deleteTagButton_deleteTagMutation$data;
  variables: deleteTagButton_deleteTagMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedIds",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "TagData",
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sortOrder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteTagButton_deleteTagMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TagMutations",
        "kind": "LinkedField",
        "name": "Tag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "SoftDeleteTagsPayload",
            "kind": "LinkedField",
            "name": "softDeleteTags",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TagsEdge",
                "kind": "LinkedField",
                "name": "cleanedTags",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tag",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "tagsTable_TagInlineFragment",
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "editTagButton_TagFragment"
                          }
                        ],
                        "args": null,
                        "argumentDefinitions": []
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "deleteTagButton_deleteTagMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TagMutations",
        "kind": "LinkedField",
        "name": "Tag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "SoftDeleteTagsPayload",
            "kind": "LinkedField",
            "name": "softDeleteTags",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "deletedIds",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TagsEdge",
                "kind": "LinkedField",
                "name": "cleanedTags",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tag",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9bfd40cb2395b4bb33d0f825c8f3167e",
    "id": null,
    "metadata": {},
    "name": "deleteTagButton_deleteTagMutation",
    "operationKind": "mutation",
    "text": "mutation deleteTagButton_deleteTagMutation(\n  $input: SoftDeleteTagsInput!\n) {\n  Tag {\n    softDeleteTags(input: $input) {\n      deletedIds\n      cleanedTags {\n        node {\n          ...tagsTable_TagInlineFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editTagButton_TagFragment on Tag {\n  id\n  data {\n    name\n    color\n    sortOrder\n  }\n}\n\nfragment tagsTable_TagInlineFragment on Tag {\n  id\n  data {\n    name\n    sortOrder\n    color\n  }\n  ...editTagButton_TagFragment\n}\n"
  }
};
})();

(node as any).hash = "880b2d02020edabf2a9fbc689a215a91";

export default node;
