import { graphql } from "babel-plugin-relay/macro";

export const EXPORT_TAGS_MUTATION = graphql`
	mutation exportTagsButton_exportTagsMutation($input: ExportTagsInput!) {
		Export {
			exportTags(input: $input) {
				file {
					url
				}
			}
		}
	}
`;
