import { graphql } from "babel-plugin-relay/macro";

export const EXPORT_ASSIGNMENTS_MUTATION = graphql`
	mutation exportAssignmentsButton_ExportAssignmentsMutation($input: ExportAssignmentsInput!) {
		Export {
			exportAssignments(input: $input) {
				file {
					url
				}
			}
		}
	}
`;
