/**
 * @generated SignedSource<<33a17a4fed4c927f330241b0710fd6f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type tagsTable_TagInlineFragment$data = {
  readonly data: {
    readonly color: string;
    readonly name: string;
    readonly sortOrder: number;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"editTagButton_TagFragment">;
  readonly " $fragmentType": "tagsTable_TagInlineFragment";
};
export type tagsTable_TagInlineFragment$key = {
  readonly " $data"?: tagsTable_TagInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"tagsTable_TagInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "tagsTable_TagInlineFragment"
};

(node as any).hash = "fea2b248d45a7ad3e3b8e9249f8e1371";

export default node;
