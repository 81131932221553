import { graphql } from "babel-plugin-relay/macro";

export const SCENARIO_FRAGMENT = graphql`
	fragment projectCard_ScenarioFragment on Scenario {
		id
		...assignmentsInProject_ScenarioFragment

		budget {
			projectBudgets {
				projectRef
				maximumBudget
				budgetedCost
				utilizedCost
			}
		}
	}
`;

export const PROJECT_FRAGMENT = graphql`
	fragment projectCard_ProjectFragment on ProjectInScenario {
		id
		project {
			id
			name
			startDate
			endDate
			address {
				lineOne
				city
				country
				postalCode
				state
				latitude
				longitude
			}
			stage {
				color
			}
			...editProjectButton_ProjectFragment
			...ProjectDateTimeDisplay_ProjectFragment
		}
		assignments {
			edges {
				node {
					person {
						id
					}
				}
			}
		}
		...assignmentsInProject_ProjectFragment
		...projectDetailsButton_ProjectInScenario
	}
`;
