import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import {
	type CreateAssignmentButton_CreateMutation,
	type SetTagsInputInput,
} from "../../__generated__/CreateAssignmentButton_CreateMutation.graphql";
import { type CreateAssignmentButton_ProjectFragment$key } from "../../__generated__/CreateAssignmentButton_ProjectFragment.graphql";
import { EditAssignmentForm, type EditAssignmentFormState } from "../ui/EditAssignmentForm";
import { SuspenseDialogWithState } from "../ui/SuspenseDialogWithState";
import { TkButtonLink } from "../ui/TkButtonLink";

const PROJECT_FRAGMENT = graphql`
	fragment CreateAssignmentButton_ProjectFragment on Project {
		id
		...EditAssignmentForm_ProjectFragment
	}
`;

const CREATE_MUTATION = graphql`
	mutation CreateAssignmentButton_CreateMutation($input: CreateAssignmentInput!) {
		Scenario {
			createAssignment(input: $input) {
				edge {
					node {
						id
						...assignmentsInProject_ProjectFragment
						...projectCard_ProjectFragment
					}
				}
			}
		}
	}
`;

interface OwnProps {
	className?: string;
	scenarioId: string;
	projectFragmentRef: CreateAssignmentButton_ProjectFragment$key;
}

export const CreateAssignmentButton = React.memo(
	({ className, scenarioId, projectFragmentRef }: OwnProps) => {
		const [isVisible, setVisible] = useState<boolean>(false);
		const project = useFragment<CreateAssignmentButton_ProjectFragment$key>(
			PROJECT_FRAGMENT,
			projectFragmentRef,
		);
		const [create] = useMutation<CreateAssignmentButton_CreateMutation>(CREATE_MUTATION);

		return (
			<div className={className}>
				<TkButtonLink
					onClick={() => {
						setVisible(true);
					}}
				>
					<div className="flex justify-content-center align-items-center">
						+ add new assignment
					</div>
				</TkButtonLink>

				<SuspenseDialogWithState<EditAssignmentFormState>
					title={"Create assignment in project"}
					isVisible={isVisible}
					onHide={() => {
						setVisible(false);
					}}
					affirmativeText={"Create"}
					formComponent={(ref, onHide) => {
						return (
							<EditAssignmentForm
								ref={ref}
								projectFragmentRef={project}
								initialState={{
									setTagsInput: [],
								}}
								onSubmit={(values, { setSubmitting }) => {
									const setTagsInput: Writable<SetTagsInputInput> = {
										tagNames: [],
										tagsRef: [],
									};
									values.setTagsInput.forEach((tag) => {
										if (tag.id) return setTagsInput.tagsRef.push(tag.id);
										else setTagsInput.tagNames.push(tag.name);
									});
									create({
										variables: {
											input: {
												scenarioId,
												projectId: project.id,
												data: {
													validAssignmentRolesRef:
														values.validAssignmentRolesRef!,
													startDate: values.startDate,
													endDate: values.endDate,
													personRef: values.personRef,
													importId: values.importId,
													isExecutive: values.isExecutive || false,
													comment: values.comment,
												},
												setTagsInput,
											},
										},
										onCompleted: () => {
											setSubmitting(false);
											onHide();
										},
									});
								}}
							/>
						);
					}}
				/>
			</div>
		);
	},
);
