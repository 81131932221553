import { InputText } from "primereact/inputtext";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	selectScenarioProjectFilters,
	setProjectViewProjectFilters,
} from "@redux/ProjectViewSlice";
import { useDebouncedState } from "../../../../hooks/use-debounced-state.hook";

export const ProjectViewNameFilter = () => {
	const projectFilters = useSelector(selectScenarioProjectFilters);
	const dispatch = useDispatch();

	const [state, setState] = useDebouncedState(
		projectFilters.filterByName ?? "",
		(newValue) => {
			dispatch(
				setProjectViewProjectFilters({
					...projectFilters,
					filterByName: newValue,
				}),
			);
		},
		200,
	);

	return (
		<div>
			<span className="p-input-icon-left w-12">
				<i className="pi pi-search" />
				<InputText
					name="name-filter"
					placeholder={"Search by Project Name"}
					value={state}
					onChange={(e) => {
						setState(e.target.value ?? "");
					}}
				/>
			</span>
		</div>
	);
};
