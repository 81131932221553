/**
 * @generated SignedSource<<03869027849e9a8099dff3fd8f7c78c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type projectCard_ProjectFragment$data = {
  readonly assignments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly person: {
          readonly id: string;
        } | null;
      };
    } | null> | null;
  };
  readonly id: string;
  readonly project: {
    readonly address: {
      readonly city: string;
      readonly country: string;
      readonly latitude: number | null;
      readonly lineOne: string;
      readonly longitude: number | null;
      readonly postalCode: string;
      readonly state: string;
    } | null;
    readonly endDate: any | null;
    readonly id: string;
    readonly name: string;
    readonly stage: {
      readonly color: string | null;
    } | null;
    readonly startDate: any | null;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectDateTimeDisplay_ProjectFragment" | "editProjectButton_ProjectFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"assignmentsInProject_ProjectFragment" | "projectDetailsButton_ProjectInScenario">;
  readonly " $fragmentType": "projectCard_ProjectFragment";
};
export type projectCard_ProjectFragment$key = {
  readonly " $data"?: projectCard_ProjectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectCard_ProjectFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "projectCard_ProjectFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Project",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Address",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lineOne",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "postalCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "state",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "latitude",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "longitude",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectStage",
          "kind": "LinkedField",
          "name": "stage",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "color",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "editProjectButton_ProjectFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectDateTimeDisplay_ProjectFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentTypesConnection",
      "kind": "LinkedField",
      "name": "assignments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssignmentTypesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Assignment",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "person",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "assignmentsInProject_ProjectFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "projectDetailsButton_ProjectInScenario"
    }
  ],
  "type": "ProjectInScenario",
  "abstractKey": null
};
})();

(node as any).hash = "0e2c8cc72cf5a981ec41be0a1bd9b770";

export default node;
