/**
 * @generated SignedSource<<c0c6176530d468e45dea48cef2ff21cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type tagsSelect_TagInlineFragment$data = {
  readonly data: {
    readonly color: string;
    readonly name: string;
  };
  readonly id: string;
  readonly " $fragmentType": "tagsSelect_TagInlineFragment";
};
export type tagsSelect_TagInlineFragment$key = {
  readonly " $data"?: tagsSelect_TagInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"tagsSelect_TagInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "tagsSelect_TagInlineFragment"
};

(node as any).hash = "55844a07b8399ffa1dcf7dbffb6a7b81";

export default node;
