/**
 * @generated SignedSource<<ce4977aeab4f7d8efac1da891e368ee0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type projectsSelect_ProjectInlineFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "projectsSelect_ProjectInlineFragment";
};
export type projectsSelect_ProjectInlineFragment$key = {
  readonly " $data"?: projectsSelect_ProjectInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"projectsSelect_ProjectInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "projectsSelect_ProjectInlineFragment"
};

(node as any).hash = "8ac54b9cc88dce27ea0db748dcf501b0";

export default node;
