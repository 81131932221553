/**
 * @generated SignedSource<<d7a194c21ff3e2b3d6c42155b035211b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type allocationBarProvider_AllocationFragment$data = {
  readonly assignment: {
    readonly comment: string | null;
    readonly endDate: any;
    readonly id: string;
    readonly importId: string | null;
    readonly isExecutive: boolean;
    readonly person: {
      readonly assignmentRole: {
        readonly id: string;
        readonly name: string;
      } | null;
      readonly id: string;
      readonly name: string;
    } | null;
    readonly project: {
      readonly id: string;
      readonly milestones: ReadonlyArray<{
        readonly date: any;
        readonly name: string;
      }>;
      readonly name: string;
      readonly stage: {
        readonly color: string | null;
        readonly id: string;
        readonly name: string;
      } | null;
      readonly volume: number | null;
    };
    readonly startDate: any;
    readonly tags: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly validAssignmentRoles: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
    readonly weight: number | null;
  } | null;
  readonly endDate: any | null;
  readonly fromInterval: number | null;
  readonly fromIntervalPercentage: number | null;
  readonly id: string;
  readonly isGap: boolean;
  readonly lengthInDays: number;
  readonly startDate: any | null;
  readonly toInterval: number | null;
  readonly toIntervalPercentage: number | null;
  readonly " $fragmentType": "allocationBarProvider_AllocationFragment";
};
export type allocationBarProvider_AllocationFragment$key = {
  readonly " $data"?: allocationBarProvider_AllocationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"allocationBarProvider_AllocationFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "allocationBarProvider_AllocationFragment"
};

(node as any).hash = "6934cd299a44c24186d0b13c6b677ff0";

export default node;
