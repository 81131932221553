/**
 * @generated SignedSource<<e1afb8a8b5f714f1d05228384db03271>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditAssignmentInput = {
  assignmentId: string;
  clientMutationId?: string | null;
  data: AssignmentDataInput;
  setTagsInput: SetTagsInputInput;
};
export type AssignmentDataInput = {
  comment?: string | null;
  endDate: any;
  importId?: string | null;
  isExecutive: boolean;
  personRef?: string | null;
  startDate: any;
  validAssignmentRolesRef: ReadonlyArray<string>;
  weight?: number | null;
};
export type SetTagsInputInput = {
  tagNames: ReadonlyArray<string>;
  tagsRef: ReadonlyArray<string>;
};
export type resizeAssignmentDialogue_UpdateAssigmentMutation$variables = {
  input: EditAssignmentInput;
};
export type resizeAssignmentDialogue_UpdateAssigmentMutation$data = {
  readonly Scenario: {
    readonly editAssignment: {
      readonly clientMutationId: string | null;
    } | null;
  };
};
export type resizeAssignmentDialogue_UpdateAssigmentMutation = {
  response: resizeAssignmentDialogue_UpdateAssigmentMutation$data;
  variables: resizeAssignmentDialogue_UpdateAssigmentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ScenarioMutations",
    "kind": "LinkedField",
    "name": "Scenario",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "EditAssignmentPayload",
        "kind": "LinkedField",
        "name": "editAssignment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "clientMutationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "resizeAssignmentDialogue_UpdateAssigmentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "resizeAssignmentDialogue_UpdateAssigmentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d289a0829f85e4215c293ad920ea8bf3",
    "id": null,
    "metadata": {},
    "name": "resizeAssignmentDialogue_UpdateAssigmentMutation",
    "operationKind": "mutation",
    "text": "mutation resizeAssignmentDialogue_UpdateAssigmentMutation(\n  $input: EditAssignmentInput!\n) {\n  Scenario {\n    editAssignment(input: $input) {\n      clientMutationId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "15785c4c1f4c68b59808d16736cf6b0c";

export default node;
