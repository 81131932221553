/**
 * @generated SignedSource<<46fa2eb2c870e60d71ff5e0ff731262a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type Permission = "AccountPermission_Auth_DriveTimesEnabled" | "AccountPermission_Auth_Field" | "AccountPermission_Auth_GapDaysEnabled" | "AccountPermission_Auth_PreConIntegration" | "AccountPermission_Auth_Reports" | "AccountPermission_Rand_DataWarehouseIntegration" | "AccountPermission_System_Root" | "UserInAccountPermission_Assessment_Edit" | "UserInAccountPermission_AssignmentRole_Edit" | "UserInAccountPermission_Division_Edit" | "UserInAccountPermission_Files_Delete" | "UserInAccountPermission_Management_Management" | "UserInAccountPermission_Maps_Read" | "UserInAccountPermission_Precon_Edit" | "UserInAccountPermission_PrivateData_Read" | "UserInAccountPermission_ProjectStages_Edit" | "UserInAccountPermission_Project_Edit" | "UserInAccountPermission_Region_Edit" | "UserInAccountPermission_Salary_Read" | "UserInAccountPermission_Scenario_Budgets" | "UserInAccountPermission_Scenario_Edit" | "UserInAccountPermission_Scenario_Masterplan" | "UserInAccountPermission_Skills_Edit" | "UserInAccountPermission_Skills_Read" | "UserInAccountPermission_Staff_Edit" | "UserInAccountPermission_System_Owner" | "UserInAccountPermission_Tag_AdminEdit" | "UserInAccountPermission_Tag_UserEdit" | "UserInAccountPermission_Tasks_Tasks" | "UserInAccountPermission_Templates_Edit" | "UserInAccountPermission_Utilization_Read";
export type PreferredViewType = "None" | "ProjectView" | "StaffView";
import { FragmentRefs } from "relay-runtime";
export type PermissionBasedNavigation_CurrentUser$data = {
  readonly accounts: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly permissionsInAccount: ReadonlyArray<Permission>;
  readonly user: {
    readonly extension: {
      readonly preferredViewType?: PreferredViewType;
      readonly showBudget?: boolean;
      readonly showVolumePerPerson?: boolean;
    };
    readonly id: string;
    readonly name: string;
  };
  readonly " $fragmentType": "PermissionBasedNavigation_CurrentUser";
};
export type PermissionBasedNavigation_CurrentUser$key = {
  readonly " $data"?: PermissionBasedNavigation_CurrentUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"PermissionBasedNavigation_CurrentUser">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "PermissionBasedNavigation_CurrentUser"
};

(node as any).hash = "ce2d36f1c49f35726c3b67ddcf80bd91";

export default node;
