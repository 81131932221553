import { useSelector } from "react-redux";
import { useMutation } from "react-relay";
import { match } from "ts-pattern";
import { ExportButton } from "@components/export-button";
import { selectScenarioProjectFilters } from "@redux/ProjectViewSlice";
import { type exportAssignmentsButton_ExportAssignmentsMutation } from "@relay/exportAssignmentsButton_ExportAssignmentsMutation.graphql";
import type { Staffing } from "@relay/staffViewPart_Query.graphql";
import { EXPORT_ASSIGNMENTS_MUTATION } from "@screens/project-view/parts/projects-grid-part/parts/export-assignments-button/export-assignments-button.graphql";
import { type ExportAssignmentsButtonProps } from "@screens/project-view/parts/projects-grid-part/parts/export-assignments-button/export-assignments-button.types";
import { applyFilter } from "@screens/project-view/parts/projects-grid-part/parts/projects-grid-part-content/projects-grid-part-content.utils";

export const ExportAssignmentsButton = ({ scenarioId }: ExportAssignmentsButtonProps) => {
	const [doExport, isExporting] = useMutation<exportAssignmentsButton_ExportAssignmentsMutation>(
		EXPORT_ASSIGNMENTS_MUTATION,
	);
	const projectFilters = useSelector(selectScenarioProjectFilters);
	return (
		<ExportButton
			isExporting={isExporting}
			doExport={(success) => {
				doExport({
					variables: {
						input: {
							input: {
								scenarioRef: scenarioId,
								projectFiltersOpt: {
									name: applyFilter(projectFilters.filterByName),
									divisions: applyFilter(projectFilters.filterByDivisions),
									regions: applyFilter(projectFilters.filterByRegions),
									stages: applyFilter(
										projectFilters.filterByStage
											? [projectFilters.filterByStage]
											: undefined,
									),
									inDateRange:
										projectFilters.filterByDateFrom ||
										projectFilters.filterByDateTo
											? {
													from: projectFilters.filterByDateFrom,
													to: projectFilters.filterByDateTo,
											  }
											: undefined,
									executives: applyFilter(projectFilters.filterByExecutives),
									staffing: match(projectFilters.filterByStaffing)
										.returnType<Staffing | undefined>()
										.with("Fully staffed", () => "FullyStaffed")
										.with("Not Fully Staffed", () => "NotFullyStaffed")
										.otherwise(() => undefined),
								},
								personFiltersOpt: {
									jobTitles: applyFilter(projectFilters.filterByAssignmentRoles),
									executives: applyFilter(projectFilters.filterByExecutives),
									ids: applyFilter(projectFilters.filterByStaff),
									skillFilters: applyFilter(projectFilters.filterBySkills),
									assignmentStatus: applyFilter(
										projectFilters.filterByAssignmentStatus,
									),
								},
							},
						},
					},
					onCompleted: (response) => {
						success(response.Export.exportAssignments?.file?.url!);
					},
				});
			}}
		/>
	);
};
