/**
 * @generated SignedSource<<7b42f4db11ab6354372d38a14e84897c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type assignmentsInProjectList_ScenarioFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentCard_ScenarioFragment">;
  readonly " $fragmentType": "assignmentsInProjectList_ScenarioFragment";
};
export type assignmentsInProjectList_ScenarioFragment$key = {
  readonly " $data"?: assignmentsInProjectList_ScenarioFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"assignmentsInProjectList_ScenarioFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "assignmentsInProjectList_ScenarioFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssignmentCard_ScenarioFragment"
    }
  ],
  "type": "Scenario",
  "abstractKey": null
};

(node as any).hash = "b227a16449bcb62524777db72f2db9eb";

export default node;
