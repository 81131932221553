/**
 * @generated SignedSource<<d998620dced5cdcda6d0db0c365a3620>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type assignmentsInProject_AssignmentInlineFragment$data = {
  readonly endDate: any;
  readonly id: string;
  readonly startDate: any;
  readonly tags: ReadonlyArray<{
    readonly data: {
      readonly color: string;
      readonly name: string;
      readonly sortOrder: number;
    };
    readonly id: string;
  }>;
  readonly validAssignmentRoles: ReadonlyArray<{
    readonly sortOrder: number;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentCard_AssignmentFragment">;
  readonly " $fragmentType": "assignmentsInProject_AssignmentInlineFragment";
};
export type assignmentsInProject_AssignmentInlineFragment$key = {
  readonly " $data"?: assignmentsInProject_AssignmentInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"assignmentsInProject_AssignmentInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "assignmentsInProject_AssignmentInlineFragment"
};

(node as any).hash = "35e913ccb481605595aa12ca5d0a95f2";

export default node;
