import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import type { SetTagsInputInput } from "@relay/CreateAssignmentButton_CreateMutation.graphql";
import { type EditAssignmentButton_AssignmentFragment$key } from "../../__generated__/EditAssignmentButton_AssignmentFragment.graphql";
import { type EditAssignmentButton_EditMutation } from "../../__generated__/EditAssignmentButton_EditMutation.graphql";
import { type EditAssignmentButton_ProjectFragment$key } from "../../__generated__/EditAssignmentButton_ProjectFragment.graphql";
import { EditAssignmentForm, type EditAssignmentFormState } from "../ui/EditAssignmentForm";
import { SuspenseDialogWithState } from "../ui/SuspenseDialogWithState";
import { TkButtonLink } from "../ui/TkButtonLink";

const PROJECT_FRAGMENT = graphql`
	fragment EditAssignmentButton_ProjectFragment on Project {
		id
		...EditAssignmentForm_ProjectFragment
	}
`;

const EDIT_MUTATION = graphql`
	mutation EditAssignmentButton_EditMutation($input: EditAssignmentInput!) {
		Scenario {
			editAssignment(input: $input) {
				update {
					project {
						id
						...assignmentsInProject_ProjectFragment
						...projectCard_ProjectFragment

						assignments {
							edges {
								node {
									...EditAssignmentButton_AssignmentFragment
								}
							}
						}
					}
				}
			}
		}
	}
`;

const ASSIGNMENT_FRAGMENT = graphql`
	fragment EditAssignmentButton_AssignmentFragment on Assignment {
		id
		startDate
		endDate
		person {
			id
		}
		validAssignmentRoles {
			id
		}
		importId
		isExecutive
		comment
		weight
		tags {
			id
			data {
				name
				color
			}
		}
	}
`;

interface OwnProps {
	className?: string;
	assignmentFragmentRef: EditAssignmentButton_AssignmentFragment$key;
	projectFragmentRef: EditAssignmentButton_ProjectFragment$key;
}

export const EditAssignmentButton = ({
	className,
	assignmentFragmentRef,
	projectFragmentRef,
}: OwnProps) => {
	const [isVisible, setVisible] = useState(false);
	const assignment = useFragment<EditAssignmentButton_AssignmentFragment$key>(
		ASSIGNMENT_FRAGMENT,
		assignmentFragmentRef,
	);
	const project = useFragment<EditAssignmentButton_ProjectFragment$key>(
		PROJECT_FRAGMENT,
		projectFragmentRef,
	);
	const [edit] = useMutation<EditAssignmentButton_EditMutation>(EDIT_MUTATION);
	return (
		<>
			<TkButtonLink
				className={className}
				icon="pi pi-pencil"
				iconPos="left"
				label="Edit"
				onClick={() => {
					setVisible(true);
				}}
			/>

			<SuspenseDialogWithState<EditAssignmentFormState>
				title={"Create assignment"}
				isVisible={isVisible}
				onHide={() => {
					setVisible(false);
				}}
				formComponent={(ref, onHide) => {
					return (
						<EditAssignmentForm
							ref={ref}
							projectFragmentRef={project}
							initialState={{
								personRef: assignment?.person?.id,
								startDate: assignment?.startDate,
								endDate: assignment?.endDate,
								validAssignmentRolesRef: assignment?.validAssignmentRoles.map(
									(r) => r.id,
								),
								importId: assignment?.importId || undefined,
								isExecutive: assignment?.isExecutive,
								comment: assignment?.comment || undefined,
								weight: assignment?.weight || undefined,
								setTagsInput: assignment.tags.map((e) => ({
									name: e.data.name,
									color: e.data.color,
									id: e.id,
								})),
							}}
							onSubmit={(values, { setSubmitting }) => {
								const setTagsInput: Writable<SetTagsInputInput> = {
									tagNames: [],
									tagsRef: [],
								};
								values.setTagsInput.forEach((tag) => {
									if (tag.id) return setTagsInput.tagsRef.push(tag.id);
									else setTagsInput.tagNames.push(tag.name);
								});
								edit({
									variables: {
										input: {
											assignmentId: assignment.id,
											data: {
												validAssignmentRolesRef:
													values.validAssignmentRolesRef!,
												startDate: values.startDate,
												endDate: values.endDate,
												personRef: values.personRef,
												importId: values.importId,
												isExecutive: values.isExecutive || false,
												comment: values.comment,
												weight: values.weight,
											},
											setTagsInput,
										},
									},
									onCompleted: () => {
										setSubmitting(false);
										onHide();
									},
								});
							}}
						/>
					);
				}}
			/>
		</>
	);
};
